import React, { useState, useEffect, useRef } from "react";

import logo from "./assets/images/logo.svg";
import logotext from "./assets/images/logotext.svg";
import googleplay from "./assets/images/googleplay.svg";
import appstore from "./assets/images/appstore.svg";
import iphone from "./assets/images/iphone.png";
import getstarted from "./assets/images/getstarted.svg";
import watchvideo from "./assets/images/watchvideo.svg";
import background from "./assets/images/background.png";
import backgroundvideo from "./assets/videos/background.mp4";

import "./App.css";

const links = {
  apple: "",
  android: "",
  multi: "",
};

function App() {
  const [landingHeight, setLandingHeight] = useState(0);
  const [videoMode, setVideoMode] = useState(false);
  const landingRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleResize() {
    setLandingHeight(landingRef.current?.clientHeight);
  }

  return (
    <div className="App">
      <header className="header">
        <div className="flexrow-center">
          {/* <img src={logo} alt="logo" style={{ marginRight: 32 }} /> */}
          <a href="/">
            <img src={logotext} alt="logotext" className="svg-shadow " />
          </a>
        </div>
        <div className="header-store-container">
          <a href={links.android}>
            <img src={googleplay} alt="googleplay" />
          </a>
          <a href={links.apple}>
            <img src={appstore} alt="appstore" />
          </a>
        </div>
      </header>
      <section ref={landingRef} className="landing">
        <article className="landing-container">
          <div className="landing-left-container">
            <h1 className="title">Revolutionizing dating</h1>
            <h2 className="subtitle">
              Welcome to our revolutionary dating app, the first of its kind to
              offer both video and physical dates directly after matching!
            </h2>
            <div className="landing-buttons-container">
              <a href={links.multi}>
                <img
                  draggable="false"
                  className="pointer "
                  src={getstarted}
                  alt="getstarted"
                />
              </a>
              {/* {!videoMode && (
                <img
                  draggable="false"
                  className="pointer landing-watchvideo"
                  src={watchvideo}
                  alt="watchvideo"
                  onClick={() => {
                    setVideoMode(true);
                    videoRef.current.play();
                  }}
                />
              )} */}
            </div>
          </div>
          <div className="landing-right-container">
            <img src={iphone} className="landing-iphone" />
          </div>
        </article>

        <video
          autoPlay
          loop
          className={"background-video"}
          src={backgroundvideo}
          ref={videoRef}
          muted
          style={{ height: landingHeight, zIndex: videoMode ? -1 : -2 }}
          onEnded={() => {
            videoRef.current.currentTime = 0;
            setVideoMode(false);
          }}
        />
      </section>

      <footer className="footer">
        <div className="footer-copyrigt-container">
          <img src={logo} alt="logo" />
          <p className="footer-text">© 2023 Chillz - All rights reserved</p>
        </div>
        <div>
          <p className="footer-title">Get the app</p>
          <div className="footer-store-container">
            <a href={links.android}>
              <img src={googleplay} alt="googleplay" />
            </a>
            <a href={links.apple}>
              <img src={appstore} alt="appstore" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
